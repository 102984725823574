import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, cn } from "../lib/helpers";
import { Figure } from "../components/figure";

import { responsiveTitle1, responsiveTitle2, base, small, responsiveTitle3 } from "../components/typography.module.css";
import { mobileHeader } from "../components/header.module.css";
import BlockText from "../components/block-text";

import { details, text, root, video, title } from "../components/project-preview.module.css";

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      mainImage {
        asset {
          url
        }
      }
    }
    about: sanityAbout {
      _rawDescription(resolveReferences: {maxDepth: 20})
      _rawContact(resolveReferences: {maxDepth: 20})
    }
  }
`;

const AboutPage = (props) => {
  const { data, errors, location } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const about = (data || {}).about;
  const seoImage = site.mainImage ? `${site.mainImage.asset.url}?w=1200&h=600` : "";

  if (!site || !about) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }


  return (
    <Layout location={location}>
      <SEO
        title="Studio"
        description={site.description}
        keywords={site.keywords}
        image={seoImage}
      />
      <Container>
        <div className="lg:grid grid-cols-12 gap-14.5">
          <div className="hidden md:block col-span-3"></div>
          <div className={cn(base, "col-span-6 ml-auto")}>
            <BlockText blocks={about._rawDescription} />
          </div>
          <div className="md:hidden">
            <br /><br />
          </div>
          <div id="contact" className={cn("pt-0 col-span-3 font-sans text-mobile-body md:text-desktop-sm")}>
            {about._rawContact && <BlockText blocks={about._rawContact} />}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default AboutPage;
